import { BaseComponent } from "../../../common/base-component.js";
import missionController from "./mission_controller";

const style = `
:host {
    display: block;
    margin-top: 0.9375rem;
}

.mission-container {
    box-shadow: 0rem 0.125rem 0.25rem #ccc;
    border-radius: 0.375rem;
    overflow: visible;
    background-color: var(--color-bg-primary);
    padding: 1.5rem 0 2rem;
    display: none;
}

@media (min-width: 48rem) {
    .mission-container {
        padding: 1.5rem 2rem 2rem 2rem;
        margin-bottom: 2rem;
    }
}

.mission-container__header {
    margin: 0;
    background-color: var(--color-bg-inverse);
}
@media (min-width: 48rem) {
    .mission-container__header {
        border-top-left-radius: 0.375rem;
        border-top-right-radius: 0.375rem;
    }
}


.mission-container__header__txt {
    font-weight: 500;
    font-size: 1.125rem;
    text-align: left;
    line-height: 1.4;
    letter-spacing: 0.03375;
    color: var(--color-fg-inverse);
    padding: 1rem;
    
}

.mission-container__header__txt span {
    color: var(--color-highlight);
}

.swiper-lazy-preloader {
    width: 42px;
    height: 42px;
    left: 50%;
    margin-left: -21px;
    margin-top: -21px;
    z-index: 10;
    transform-origin: 50%;
    box-sizing: border-box;
    border: 4px solid var(--swiper-preloader-color, var(--swiper-theme-color));
    border-radius: 50%;
    border-color: transparent var(--orange) var(--orange);
    position: relative;
    top: 2rem;
    animation: swiper-preloader-spin 1s infinite linear;
}
.loader-container {
    height: 6rem;
    background: white;
    margin-top: 1rem;
}
@keyframes swiper-preloader-spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

`

customElements.define(
    "mission-container",
    class extends BaseComponent {

        constructor() {
            super(style);
            missionController.subscribe('data-ready', () => {
                console.log('Mission Center API Call: Success');
                this.shadowRoot.querySelector('.loader-container').style = 'display: none;';
                this.shadowRoot.querySelector('.mission-container').style = 'display: block;';
            })
            missionController.subscribe('API_FAILURE', (data) => {
                console.log('Mission Center API Call: ', data);
                this.shadowRoot.querySelector('.loader-container').style = 'display: block;';
                this.shadowRoot.querySelector('.mission-container').style = 'display: none;';
            })
            missionController.subscribe('mission-error', (alias) => {
                const failedMission = this.querySelector(`mission-single[data-alias="${alias}"]`);
                if(failedMission) failedMission.remove();
            })
        }

        render() {
            return `
                    <h2 class="mission-container__header">
                        <div class="mission-container__header__txt"><span>Missions</span> Center</div>
                    </h2>
                    <div class="loader-container">
                        <div class="swiper-lazy-preloader"></div>
                    </div>
                    <div class="mission-container">
                        <slot name="missions"></slot>
                    </div>
            `
        }
    },
);
